import {EditInitPlacement} from "../Constant";

export const rootPath = 'https://arcflot.ru'
export const MapApi = `${rootPath}/backend/index.php?mod=map`;
export const ShipsApi = `${rootPath}/backend/index.php?mod=ships`;
export const ShipApi = `${rootPath}/backend/index.php?mod=ships`;
export const ScheduleApi = `${rootPath}/backend/index.php?mod=gant`;
export const PointsApi = `${rootPath}/backend/index.php?mod=points`;

export const RequestsApi = `${rootPath}/backend/index.php?mod=tables&tab=y_schedule`;
export const PostRequestsApi = `${rootPath}/backend/index.php?mod=edit-tables&tab=y_schedule`;
export const ModelsApi = `${rootPath}/backend/index.php?mod=tables&tab=models`;
export const PostModelsApi = `${rootPath}/backend/index.php?mod=edit-tables&tab=models`;
export const ConditionsApi = `${rootPath}/backend/index.php?mod=tables&tab=constant`;
export const PostConditionsApi = `${rootPath}/backend/index.php?mod=edit-tables&tab=constant`;
export const SailingSectionsApi = `${rootPath}/backend/index.php?mod=tables&tab=sailing_section`;
export const PostSailingSectionsApi = `${rootPath}/backend/index.php?mod=edit-tables&tab=sailing_section`;
export const InitPlacementsApi = `${rootPath}/backend/index.php?mod=tables&tab=placement_init`;
export const PostInitPlacementsApi = `${rootPath}/backend/index.php?mod=edit-tables&tab=placement_init`;

export const ResetTableApi = `${rootPath}/backend/index.php?mod=backup`;
